<template>
	<div id="app">
		<!-- 路由页面 -->
		<router-view />
	</div>
</template>
<script>
	export default {
		data() {
			return {
				token: ''
			};
		},
		mounted() {
			
		},
		destroyed() {

		},
		methods: {


		},


	};
</script>
<style>
	@import './assets/theme/app.scss';
	@import './assets/theme/styles.scss';
	body {
		background: #f2f2f2;
	}

	#app {
		display: flex;
	}
	/* .el-upload{
		display: flex !important;
	} */
</style>