import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import App from './App.vue'
import router from './router'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import less from 'less'
import  * as http  from './utils/request.js';
import store from './store';
import uploadPicture from './components/uploadFrom';
Vue.prototype.$http = http;
Vue.config.productionTip = false
Vue.use(ElementUI, { locale })
Vue.prototype.$axios = axios;
Vue.use(less)
Vue.use(uploadPicture);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
